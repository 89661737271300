import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import {
  format,
  addDays,
  startOfWeek,
  getISOWeeksInYear,
  getYear,
  getISOWeek,
} from "date-fns";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const { user } = useContext(UserContext);
  const currentYear = getYear(new Date());
  const currentWeek = getISOWeek(new Date());
  const [year, setYear] = useState(currentYear); // Default year
  const [weekNumber, setWeekNumber] = useState(currentWeek);
  const [approvals, setApprovals] = useState({}); // State to manage approval status
  const [timesheetsData, setTimesheetsData] = useState([]); // State to store fetched data
  const [projectOptions, setProjectOptions] = useState([]); // State to store project options for the dropdown
  const [selectedProject, setSelectedProject] = useState(null);
  const [expandedEmployees, setExpandedEmployees] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADMIN_API_ENDPOINT}/timesheets/approved/${user.Data.empId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setTimesheetsData(data.timesheets);

          // Extract unique project names
          const uniqueProjects = [
            ...new Map(
              data.timesheets.map((item) => [
                item.project._id,
                { label: item.project.name, value: item.project._id },
              ])
            ).values(),
          ];

          if (uniqueProjects.length > 0) {
            setProjectOptions(uniqueProjects);
            setSelectedProject(uniqueProjects[0].value);
          }
        } else {
          console.error("Failed to fetch timesheets:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const totalWeeks = getISOWeeksInYear(new Date(year, 0, 1));
  const currentWeekStart = startOfWeek(new Date(year, 0, 1), {
    weekStartsOn: 0,
  });
  const selectedWeekStart = addDays(currentWeekStart, (weekNumber - 1) * 7);
  const selectedWeekEnd = addDays(selectedWeekStart, 6); // Get the end of the week

  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(addDays(selectedWeekStart, i));
  }

  const incrementWeek = () => {
    if (weekNumber < totalWeeks) {
      setWeekNumber(weekNumber + 1);
    }
  };

  const decrementWeek = () => {
    if (weekNumber > 1) {
      setWeekNumber(weekNumber - 1);
    }
  };

  const formattedData = timesheetsData
    .filter((item) => {
      // Filter data based on selected project
      if (selectedProject) {
        return item.project._id === selectedProject;
      }
      return true;
    })
    .map((item) => {
      const row = {
        name: item.employeeName,
        empId: item.empId,
        taskId: item.task._id,
        week: item.week,
        subproject: item.subproject.name,
        project: item.project.name,
        task: item.task.name,
        projectId: item.project._id,
        subprojectId: item.subproject._id,
        total: item.totalHours || 0,
      };

      // Mapping hours for each day of the week
      daysOfWeek.forEach((day) => {
        const dayKey = format(day, "EEE").toLowerCase(); // "sun", "mon", etc.
        const dayEntry = item.entries[0]?.hours[dayKey]?.time || "-";

        row[format(day, "dd/MM/yyyy")] = {
          WorkHours: dayEntry,
        };
      });

      row.approved = approvals[item.employeeName] || null;
      return row;
    });

  const filteredData = formattedData.filter(
    (rowData) =>
      rowData.week ===
      `${format(selectedWeekStart, "dd/MM/yyyy")} - ${format(
        selectedWeekEnd,
        "dd/MM/yyyy"
      )}`
  );

  const groupedData = filteredData.reduce((acc, rowData) => {
    const { name } = rowData;
    if (!acc[name]) {
      acc[name] = [];
    }
    acc[name].push(rowData);
    return acc;
  }, {});

  return (
    <div
      className="w-full"
      style={{
        height: "calc(100vh - 190px)",
        boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.3)",
        border: "1px solid rgba(128, 128, 128, 0.211)",
        overflow: "scroll",
      }}
    >
      {/* Week Selection and Buttons */}
      <div
        className="w-full flex"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label
            style={{
              marginRight: "10px",
              fontWeight: "bold",
              color: "#00000080",
              fontSize: "18px",
            }}
          >
            Select Week:
          </label>
          <Button
            icon="pi pi-minus"
            onClick={decrementWeek}
            style={{
              marginRight: "5px",
              backgroundColor: "#29295FFF",
              border: "1px solid #29295FFF",
            }}
            disabled={weekNumber === 1}
          />
          {format(selectedWeekStart, "dd/MM/yyyy")} -{" "}
          {format(selectedWeekEnd, "dd/MM/yyyy")}
          <Button
            icon="pi pi-plus"
            onClick={incrementWeek}
            style={{
              marginLeft: "5px",
              backgroundColor: "#29295FFF",
              border: "1px solid #29295FFF",
            }}
            disabled={weekNumber === totalWeeks}
          />
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            paddingRight: "50px",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontWeight: "bold",
              color: "#00000080",
              fontSize: "18px",
            }}
          >
            Select Project:
          </label>
          <Dropdown
            value={selectedProject}
            options={projectOptions}
            onChange={(e) => setSelectedProject(e.value)}
            placeholder="Select Project"
          />
        </div>
      </div>
      {/* Week Number and Pagination */}
      <div>
        {/* Timesheet Data Table */}
        <div
          style={{
            border: "1px solid #EBEBEB",
            padding: "25px",
            boxShadow: "1px 2px 4px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            padding: "20px",
            margin: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                width: "20%",
                fontWeight: "bold",
                color: "#373D3F",
                textAlign: "center",
              }}
            >
              Name
            </div>
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
              (days, index) => (
                <div
                  key={index}
                  style={{
                    width: "8.88%",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#0069FFFF",
                  }}
                >
                  {days}
                </div>
              )
            )}
            <div
              style={{
                width: "8.88%",
                textAlign: "center",
                fontWeight: "bold",
                color: "#373D3F",
              }}
            >
              Total
            </div>
          </div>
          <Divider />
          <div style={{ paddingTop: "15px" }}>
            {Object.keys(groupedData).length === 0 ? (
              <p>No timesheet found</p>
            ) : (
              Object.keys(groupedData).map((employeeName, index) => {
                const totalHoursPerDay = daysOfWeek.map((day) => {
                  return groupedData[employeeName].reduce((total, rowData) => {
                    const dayData = rowData[format(day, "dd/MM/yyyy")] || {};
                    const workHours = dayData.WorkHours;
                    return !isNaN(workHours) &&
                      typeof parseFloat(workHours) === "number"
                      ? total + parseFloat(workHours)
                      : total;
                  }, 0);
                });

                const displayHoursPerDay = daysOfWeek.map((day, dayIndex) => {
                  const dayData = groupedData[employeeName].find(
                    (rowData) => rowData[format(day, "dd/MM/yyyy")]
                  );
                  const workHours = dayData
                    ? dayData[format(day, "dd/MM/yyyy")].WorkHours
                    : null;

                  return isNaN(workHours)
                    ? workHours
                    : totalHoursPerDay[dayIndex];
                });

                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                      border: "1px solid #EBEBEB",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "transform 0.3s ease",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                      padding: "10px",
                    }}
                    onClick={() => {
                      const weekRange = `${format(
                        selectedWeekStart,
                        "dd-MM-yyyy"
                      )} - ${format(selectedWeekEnd, "dd-MM-yyyy")}`;
                      navigate(
                        `/timesheets/approvedemployee/${employeeName}/${weekRange}/${selectedProject}`
                      );
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: "20%",
                          textAlign: "center",
                          color: "#00000080",
                          fontWeight: "bolder",
                          fontSize: "16px",
                        }}
                      >
                        {employeeName}
                      </div>
                      {daysOfWeek.map((day, index) => {
                        // const isSaturday = format(day, "eeee") === "Saturday";
                        // const isSunday = format(day, "eeee") === "Sunday";
                        const workHours = displayHoursPerDay[index];

                        return (
                          <div
                            key={index}
                            style={{
                              width: "8.88%",
                              textAlign: "center",
                              fontWeight: "bold",
                              color: isNaN(workHours) ? "red" : "black",
                            }}
                          >
                            {workHours}
                          </div>
                        );
                      })}
                      <div
                        style={{
                          width: "8.88%",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {totalHoursPerDay.reduce(
                          (sum, hours) => sum + (isNaN(hours) ? 0 : hours),
                          0
                        ) || "-"}
                      </div>
                    </div>
                    {expandedEmployees.includes(employeeName) &&
                      groupedData[employeeName].map((rowData, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            border: "1px solid #EBEBEB",
                            alignItems: "center",
                            cursor: "pointer",
                            transition: "transform 0.3s ease",
                            borderRadius: "5px",
                            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
                            background: "#F8F9FAFF",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.004)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <div
                              style={{
                                width: "20%",
                                fontWeight: "bold",
                                color: "#00000080",
                              }}
                            >
                              {/* {rowData.name} */}
                            </div>
                            {daysOfWeek.map((day, i) => {
                              const dayData =
                                rowData[format(day, "dd/MM/yyyy")] || {};
                              const workHours = dayData.WorkHours || "-";

                              return (
                                <div
                                  key={i}
                                  style={{
                                    width: "8.88%",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "#373D3F",
                                  }}
                                >
                                  {workHours}
                                </div>
                              );
                            })}
                            <div
                              style={{
                                width: "8.88%",
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#373D3F",
                              }}
                            >
                              {totalHoursPerDay.reduce(
                                (sum, hours) =>
                                  sum + (isNaN(hours) ? 0 : hours),
                                0
                              ) || "-"}
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#F4F6F7",
                              borderTop: "1px solid #EBEBEB",
                              marginTop: "-10px",
                              display: "flex",
                              gap: "50px",
                            }}
                          >
                            <div>
                              <strong>Subproject:</strong>{" "}
                              {rowData.subproject || "N/A"}
                            </div>
                            <div>
                              <strong>Task:</strong> {rowData.task}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
